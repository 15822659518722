
import React, { FC, MouseEvent, useEffect } from 'react'

import { formatClassList, joinStrings } from '@bscs-dev-team/bscs-design-system-common'

import Category from '../category'

type CategoryItem = {
  alt: string,
  image: string,
  path: string,
  title: string,
  variant: string
}

type ResourceCategoriesProps = {
  activeFilters: any[],
  categories: CategoryItem[],
  className?: string,
  filterHash?: string,
  setActiveFilters: (value: any[]) => void,
  [other:string]: unknown
}

const CATEGORY_WRAPPER: string = `
  md:w-1/4
  w-1/2
`

const FLEX_CONTAINER: string = `
  flex
  flex-wrap
  justify-center
`

const setUpdatedFilters = (
  title: string,
  updatedFilters: string[],
  setActiveFilters: (value: string[]) => void,
  categories: CategoryItem[]
): void => {
  for (let key in categories) {
    if (updatedFilters.includes(categories[key].title) && categories[key].title !== title) {
      updatedFilters.splice(updatedFilters.indexOf(categories[key].title), 1)
    }
  }

  setActiveFilters(updatedFilters)
}

const categoryFilter = (
  elem: HTMLElement | null,
  title: string,
  activeFilters: string[],
  setActiveFilters: (value: any[]) => void,
  categories: CategoryItem[]
): void => {
  const id: string | null | undefined = elem?.closest('a')?.getAttribute('id')

  if (id) {
    document.getElementById(id)?.scrollIntoView({behavior: "smooth", block: "start"})
  }

  if (activeFilters && activeFilters.includes(title) === false) {
    const updatedFilters = activeFilters.concat(title)
    setUpdatedFilters(title, updatedFilters, setActiveFilters, categories)
  }
}

const ResourceCategories: FC<ResourceCategoriesProps> = ({
  activeFilters,
  categories,
  className,
  filterHash,
  setActiveFilters,
  ...other
}: ResourceCategoriesProps) => {
  useEffect(() => {
    if (filterHash) {
      categoryFilter(null, filterHash, activeFilters, setActiveFilters, categories)
    }
  }, [filterHash])

  const formattedCategoryContainer: string = formatClassList(CATEGORY_WRAPPER)
  const formattedFlexContainer: string = formatClassList(
    className
      ? joinStrings(' ', FLEX_CONTAINER, className)
      : FLEX_CONTAINER
  )

  return (
    <div className={formattedFlexContainer} {...other}>
      {
        categories.map((category: CategoryItem, index: number) => {
          return (
            <div className={formattedCategoryContainer} key={index}>
              <Category
                category={category}
                onClick={(e: MouseEvent, category: Category) => {
                  e.preventDefault()

                  const elem: HTMLElement | null = (e.target as HTMLAnchorElement)

                  if (elem) {
                    categoryFilter(elem, category.title, activeFilters, setActiveFilters, categories)
                  }
                }}
              />
            </div>
          )
        })
      }
    </div>
  )
}

export default ResourceCategories

export {
  setUpdatedFilters,
  categoryFilter
}

