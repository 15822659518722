import React, { FC, MouseEvent } from 'react'
import { Link } from 'gatsby'

import { formatClassList, joinStrings } from '@bscs-dev-team/bscs-design-system-common'

import './category.css'


type Category = {
  alt: string,
  id?: string,
  image: string,
  path?: string,
  title: string,
  variant: string
}

type CategoryProps = {
  category: Category
  onClick: (e: MouseEvent, category: Category) => void
}

const CATEGORY: string = `
  border-3
  categoryImageChild
  max-w-xs
  p-3
  rounded-full
  w-1/2
`

const FLEX_CONTAINER: string = `
  flex
  flex-row
  justify-center
`

const GREEN: string = `
  ${CATEGORY}
  border-bscs-green-700
  hover:bg-bscs-green-700
`

const IMAGE: string = `
  categoryImageGrandChild
`

const RED: string = `
  ${CATEGORY}
  border-bscs-red-700
  hover:bg-bscs-red-700
`

const TEXT: string = `
  text-2xl
  leading-5
  lg:mt-3
  md:mb-0
  md:mt-3
  mt-3
  text-center
`

const VIOLET: string = `
  ${CATEGORY}
  border-bscs-violet-700
  hover:bg-bscs-violet-700
`

const WRAPPER: string = `
  categoryImageParent
  w-full
`

const YELLOW: string = `
  ${CATEGORY}
  border-bscs-yellow-700
  hover:bg-bscs-yellow-700
`

const VARIANTS: Record<string, string> = {
  'violet': VIOLET,
  'yellow': YELLOW,
  'red': RED,
  'green': GREEN
}

// const setCategoryFilter = (
//   e: MouseEvent,
//   title: string,
//   runCategoryFilter: (elem: HTMLElement | null, value: string) => void
// ) => {
//   e.preventDefault()

//   runCategoryFilter((e.target as HTMLElement), title)
// }

const Category: FC<CategoryProps> = ({
  category,
  onClick,
}: CategoryProps) => {
  const formattedCategory: string = formatClassList(
    joinStrings(
      ' ',
      VARIANTS[category.variant.toLowerCase()],
      category.title.toLowerCase().replace(/\s/g, '-')
    )
  )

  const formattedImage: string = formatClassList(IMAGE)
  const formattedText: string = formatClassList(TEXT)
  const formattedWrapper: string = formatClassList(
    joinStrings(
      ' ',
      WRAPPER,
      category.title.toLowerCase().replace(/\s/g, '-')
    )
  )
  const formattedFlexContainer: string = formatClassList(FLEX_CONTAINER)

  // onClick={(e: MouseEvent) => setCategoryFilter(e, category.title, runCategoryFilter)}
  if (category.path) {
    return (
      <Link
        id={category.id}
        onClick={(e: MouseEvent) => onClick(e, category)}
        to={category.path}
      >
        <div className={formattedWrapper}>
          <div className={formattedFlexContainer}>
            <div className={formattedCategory} style={{maxWidth: '105px'}}>
              <img
                alt={category.alt}
                className={formattedImage}
                src={category.image}
              />
              </div>
          </div>
          <div className={formattedFlexContainer}>
            <p className={formattedText}>
              {category.title.split(" ")[0]}
              <br />
              {category.title.split(" ")[1]}
            </p>
          </div>
        </div>
      </Link>
    )
  }

  return (
    <div
      id={category.id}
      onClick={(e: MouseEvent) => onClick(e, category)}
    >
      <div className={formattedWrapper}>
        <div className={formattedFlexContainer}>
          <div className={formattedCategory} style={{maxWidth: '105px'}}>
            <img
              alt={category.alt}
              className={formattedImage}
              src={category.image}
            />
            </div>
        </div>
        <div className={formattedFlexContainer}>
          <p className={formattedText}>
            {category.title.split(" ")[0]}
            <br />
            {category.title.split(" ")[1]}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Category

