import React, { FC, useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import { Frontmatter } from '../../templates/template-types'

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  PageTitle,
  PillBadge,
  TitleBorder,
  VerticalCard,
  VerticalCardBody
} from '@bscs-dev-team/bscs-design-system-core'

import FilterByDropdown from '../../components/filter-by-dropdown/filter-by-dropdown'
import FilterByRow from '../../components/filter-by-row/filter-by-row'
import Layout from '../../components/layout/layout'
import ResourceCategories from '../../components/resource-categories/resource-categories'
import SEO from '../../components/seo'
import SearchBy from '../../components/search-by/search-by'

import classroomInstruction from '../../images/educator-resource-center/classroom-instruction.svg'
import professionalLearning from '../../images/educator-resource-center/professional-learning.svg'
import leadershipWork from '../../images/educator-resource-center/leadership-work.svg'
import citizenScience from '../../images/educator-resource-center/citizen-science.svg'


type Node = {
  excerpt: string,
  frontmatter: Frontmatter
}

type Edge = {
  node: Node
}

type AllMdx = {
  edges: Edge[]
}

type Data = {
  allMdx: AllMdx
}

type ERCProps = {
  data: Data
}

type CategoryItem = {
  alt: string,
  id: string
  image: string,
  path: string,
  title: string,
  variant: string
}

const categories: CategoryItem[] = [
  {
    alt: 'Stick figures around a table',
    id: 'classroom-instruction',
    image: classroomInstruction,
    path: '/resources/educator-resource-center#classroom-instruction',
    title: 'Classroom Instruction',
    variant: 'violet'
  },
  {
    alt: 'Stick figures around a whiteboard',
    id: 'professional-learning',
    image: professionalLearning,
    path: '/resources/educator-resource-center#professional-learning',
    title: 'Professional Learning',
    variant: 'yellow'
  },
  {
    alt: 'Clipboard',
    id: 'leadership-work',
    image: leadershipWork,
    path: '/resources/educator-resource-center#leadership-work',
    title: 'Leadership Work',
    variant: 'red'
  },
  {
    alt: 'Magnifying glass',
    id: 'citizen-science',
    image: citizenScience,
    path: '/resources/educator-resource-center#citizen-science',
    title: 'Citizen Science',
    variant: 'green'
  }
]


const EducatorResourceCenter: FC<ERCProps> = ({ data }: ERCProps) => {
  const [activeFilters, setActiveFilters] = useState<string[]>([])

  const categoryFilters: string[] = ['Classroom Instruction','Professional Learning','Leadership Work','Citizen Science']

  const resources: Edge[] = data.allMdx.edges

  const filterItems: Record<string,(string | boolean | string[])[]> = {
    //Index 0 for if multiple values are allowed to be selected
    'gradeLevel': ['Grade Level', true, ['Elementary', 'Middle', 'High', 'Postsecondary']],
    'discipline': ['Discipline', true, ['Life Sciences', 'Physical Sciences', 'Earth Sciences', 'Multidisciplinary Sciences']],
    'programLength': ['Program Length', false, ['Full Year', 'Modules']]
  }

  return (
    <React.Fragment>
      <SEO
        title="Science Teachers Instructional & Professional Learning Resources"
        description="BSCS Science Learning is an independent nonprofit offering science teachers instructional & professional learning resources across the US."
        canonicalUrl="https://bscs.org/resources/educator-resource-center/"
      />
      <Layout>
        <section className="section">
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link className='inline-anchor' to='/'>Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                Resources
              </BreadcrumbItem>
              <BreadcrumbItem>
                Educator Resource Center
              </BreadcrumbItem>
            </Breadcrumb>
            <PageTitle>Educator Resource Center</PageTitle>
            <TitleBorder />
            <p>Welcome to the Educator Resource Center — where you will discover resources to support classroom instruction, professional learning, leadership work, and citizen science efforts.</p>
            <ResourceCategories
              activeFilters={activeFilters}
              categories={categories}
              setActiveFilters={(activeFilters: string[]) => setActiveFilters(activeFilters)}
            />
            <hr />
          </div>
        </section>
        <section className="section" style={{ padding: '.75rem 1.5rem' }}>
          <div>
            <div>
              <div className="py-2">
                <SearchBy />
              </div>
              <div className="py-2 ml-auto">
                <FilterByDropdown
                  categoryFilters={categoryFilters}
                  items={filterItems}
                  activeFilters={activeFilters}
                  setActiveFilters={(activeFilters: string[]) => setActiveFilters(activeFilters)}
                />
              </div>
            </div>
              <FilterByRow
                categoryFilters={categoryFilters}
                activeFilters={activeFilters}
                setActiveFilters={(activeFilters: string[]) => setActiveFilters(activeFilters)}
              />
            <hr />
          </div>
        </section>
        <section>
            <div className="flex flex-wrap w-full">
              {
                resources.map((edge, index) => {
                  let dataFilter = JSON.parse(JSON.stringify(edge.node.frontmatter))
                  dataFilter['excerpt'] = edge.node.excerpt

                  return (
                    <div
                      className="flex justify-center md:w-1/2 lg:w-1/3 xl:w-1/4 w-full"
                      key={`resource-${index}`}
                    >
                      <VerticalCard
                        className="flex w-full"
                        data-filter={JSON.stringify(dataFilter)}
                        id={`resource-${index}`}
                      >
                       <Img
                          alt={edge.node.frontmatter.alt}
                          className="h-64 rounded-t w-full"
                          fluid={edge.node.frontmatter.image.childImageSharp.fluid}
                        />
                        <h3 className="px-6">{edge.node.frontmatter.title}</h3>
                        <VerticalCardBody className="flex-auto">
                          <div className="flex">
                            <div className="mr-auto mb-3">
                              <div className="flex flex-wrap mb-3">
                                {
                                  edge.node.frontmatter.areas.map((area: string, index: number) => {
                                    const areaMap: Record<string, string> = {
                                      'Classroom Instruction': 'violet',
                                      'Professional Learning': 'yellow',
                                      'Leadership Work': 'red',
                                      'Citizen Science': 'green'
                                    }

                                    return (
                                      <React.Fragment key={`${edge.node.id}-area-${index}`}>
                                        <PillBadge
                                          className="mt-1 mr-1"
                                          variant={areaMap[area]}
                                        >
                                          {area}
                                        </PillBadge>
                                      </React.Fragment>
                                    )
                                  })
                                }
                              </div>
                              <p>{edge.node.frontmatter.cardDescription}</p>
                              <p>{!edge.node.frontmatter.cardDescription && edge.node.excerpt}</p>
                            </div>
                          </div>
                        </VerticalCardBody>
                        <div className="flex items-end justify-end p-5 w-full">
                          <Link
                            to={`/resources/educator-resource-center/${edge.node.frontmatter.title.replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()}`}
                          >
                            <Button variant='indigo' title='Read More'>Read More</Button>
                          </Link>
                        </div>
                      </VerticalCard>
                    </div>
                  )
                })
              }
            </div>
        </section>
      </Layout>
    </React.Fragment>
  )
}

export default EducatorResourceCenter

export const educatorResourceQuery = graphql`
  query educatorResourceQuery {
    allMdx(
      filter: {frontmatter: { status: {eq: "Ready"}, page: {eq: "educator-resource-center"}}}
      sort: {
        fields: [frontmatter___sortOrder, frontmatter___title],
      }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 200)
          frontmatter {
            additionalTags,
            alt,
            areas,
            cardDescription,
            date,
            discipline,
            gradeLevel,
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            programLength,
            sortOrder,
            title,
            page
          }
        }
      }
    }
  }
`
